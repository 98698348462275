export enum RightToWork {
	UKPassport = 1,
	BirthCertificate = 2,
	EuropeanPassport = 3,
	Visa = 4,
	Other = 5,
	DBS = 6,
	Qualification = 7,
	ProofOfAddress = 8,
	DrivingLicense = 9,
	ProhibitionCheck = 10,
	QTSCertificate = 11,
	Reference = 12,
	DBSUpdateService = 13,
	HomeOfficeShareCode = 14,
	CV = 15,
	NationalInsurance = 16,
	SafeguardingCertificate = 17,
	RTWVerification = 18,
	InterviewNotes = 19,
	ProofOfNameChange = 20,
	OverseasCriminalRecordCheck = 21,
	EWCConfirmation = 22,
	ForeignPassport = 23,
	SafeguardingCertificateWithExpiry = 24,
	DfeCheck = 25,
	FaceToFace = 26,
	TenYearWorkHistory = 27,
	EWCConfirmationInternalOnly = 28,
	ImposterCheck = 29,
	RTWPhoto = 30,
	DeclarationPack = 31,
	ImmunisationReport = 32,
	GMCCertificate = 33,
	NMCCertificate = 34,
	HCPCCertificate = 35,
	BasicLifeSupportCertificate = 36,
	PreventTraining = 37,
	CyberSecurity = 38,
	BiometricResidencePermit = 39,
	Disclaimer = 40,
	OnlineSearch = 41,
	MandatoryTraining = 42,
	ManualHandlingTraining = 43,
	CrisisTraining = 44,
	NurseTraining = 45,
	MandatoryTrainingCertificate = 46,
	ProofOfBankAccount = 47,
	ApplicationRegistrationForm = 48,
	ProofOfNMCRegistration = 49,
	StaffProfile = 50,
}

export enum DocumentLevelTypes {
	CompanyCandidates = 1000,
	CompanyBookingsClient = 2000,
	CompanyBookingsCandidate = 2001,
	CompanyBookingsBoth = 2002,
	CompanyClients = 3000,
}

// These document types can be used with the automatic candidate verification feature
export const rightToWorkPhotoIds = [RightToWork.UKPassport, RightToWork.EuropeanPassport, RightToWork.DrivingLicense];
