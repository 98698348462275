import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { toggleLoading, setAlert } from "../../../state/creators";
import { ApiResultModel } from "../../../_models/api.result.model";
import { ConvertTargetPeriod, ConvertTargetType, PermissionsValidate, UpdateFilter } from "../../../functions/helpers";
import { Permissions } from "../../../_enums/permissions.enum";
import { AlertType } from "../../../state/reducers/setAlert";
import { IHttpResponse } from "../../../functions/http-functions/http";
import { useAuth } from "../../../state/context/auth.store";
import { ModalStyles } from "../../../styles/modals";
import { IBranch } from "../../../_models/data.branch.model";
import { UserInterface } from "../../../_types/user";
import { DefaultPaginationModel } from "../../../_models/data.defaultPagination.model";
import { ListingModel } from "../../../_models/data.listingResult.model";
import { FilterModel } from "../../../_models/data.filter.model";
import Table from "../../table";
import { Note } from "../../../styles/forms/inputs";
import { ITarget } from "../../../_models/data.target.model";
import { DeleteTarget, GetUserTargets } from "../../../functions/http-functions/targets";
import ConfirmModal from "../../modals/confirmModal";
import { TableOptionsStyled } from "../../../styles/tables/options";
import { StandardButtonAnchor } from "../../../styles/type/buttons";
import ButtonIcon from "../../icons/buttons";
import AddTargetModal from "../../modals/addTarget";

type Props = {
	user: UserInterface;
	targets: ITarget[];
	total: number;
	toggleLoading: (loading: boolean) => void;
	setAlert: (alert: AlertType) => void;
};

const UserTargets: React.FC<Props> = ({ user, targets, total, toggleLoading, setAlert }) => {
	const { auth } = useAuth();
	const [activeTarget, setActiveTarget] = useState<ITarget>(null);
	const [removeTargetModalIsOpen, setRemoveTargetModalIsOpen] = useState<boolean>(false);
	const [addTargetModalIsOpen, setAddTargetModalIsOpen] = useState<boolean>(false);

	const [{ resultItems, pageNo, pageSize, paginationItems, totalRecords, resultItemsAll, filters, order }, setState] = useState({
		...new DefaultPaginationModel(),
		...{
			totalRecords: total,
			resultItemsAll: [],
			filters: [],
			order: null,
		},
	});

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			...new ListingModel({
				pageNo,
				pageSize,
				resultItems: targets.map((target: ITarget) => ({
					...target,
					typeName: ConvertTargetType(target.type),
					targetPeriod: ConvertTargetPeriod(target.reportPeriod),
				})),
				resultItemsAll,
				totalRecords: total,
			}),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updatePage = async (type, config?, tableFilter?, orderFilter?) => {
		const pageNoValue = type === "reset" || type === "start" ? 1 : config?.pageNo || pageNo;
		const pageSizeValue = config?.pageSize || pageSize;

		const FilterValues = await UpdateFilter(type, {
			pageNo: pageNoValue,
			pageSize: pageSizeValue,
		});

		if (tableFilter) {
			setState((prevState) => ({
				...prevState,
				...{
					filters: tableFilter,
				},
			}));
		}

		if (orderFilter) {
			setState((prevState) => ({
				...prevState,
				...{
					order: orderFilter,
				},
			}));
		}

		filter(FilterValues, tableFilter || filters, orderFilter || order);
	};

	const filter = async (FilterValues, TableFilter, OrderFilter) => {
		try {
			toggleLoading(true);
			const res = await GetUserTargets(auth.token, user._id, new FilterModel(FilterValues, TableFilter, OrderFilter));
			setState((prevState) => ({
				...prevState,
				...FilterValues,
				...new ListingModel(
					{
						resultItems: res.data.map((target: ITarget) => ({
							...target,
							typeName: ConvertTargetType(target.type),
							targetPeriod: ConvertTargetPeriod(target.reportPeriod),
						})),
						totalRecords: res.total,
					},
					FilterValues
				),
			}));
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setAlert({ type: "error", message: ErrorResult.message });
		} finally {
			toggleLoading(false);
		}
	};

	const removeTarget = async (e: MouseEvent, branch: IBranch) => {
		try {
			toggleLoading(true);
			await DeleteTarget(auth.token, activeTarget._id);
			updatePage("reset");
			setAlert({ message: "Target removed successfully" });
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setAlert({ type: "error", message: ErrorResult.message });
		} finally {
			setRemoveTargetModalIsOpen(false);
			toggleLoading(false);
		}
	};

	const TableProperties: { navigate?: any; columns?: any } = {
		columns: [
			{
				Name: "Start",
				Value: "start",
				Type: "Date",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "End",
				Value: "end",
				Type: "Date",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "Target Type",
				Value: "typeName",
				Type: "String",
			},
			{
				Name: "Target Value",
				Value: "value",
				Type: "String",
			},
			{
				Name: "Target Period",
				Value: "targetPeriod",
				Type: "String",
			},
		],
	};

	if (PermissionsValidate(auth.token, [Permissions.CanManageCustomerUsers])) {
		TableProperties.columns.push({
			Name: null,
			Value: "_id",
			Filter: null,
			ReturnType: "item",
			ReturnTypeAction: (e, t) => {
				setActiveTarget(t);
				setAddTargetModalIsOpen(true);
			},
			Text: "Edit",
		});
		TableProperties.columns.push({
			Name: null,
			Value: "_id",
			Filter: null,
			ReturnType: "item",
			ReturnTypeAction: (e, t) => {
				setActiveTarget(t);
				setRemoveTargetModalIsOpen(true);
			},
			Text: "Remove",
		});
	}

	return (
		<>
			{PermissionsValidate(auth.token, [Permissions.CanManageCustomerUsers]) ? (
				<TableOptionsStyled>
					<StandardButtonAnchor onClick={() => setAddTargetModalIsOpen(true)}>
						<ButtonIcon type="add" />
						Add Target
					</StandardButtonAnchor>
				</TableOptionsStyled>
			) : null}
			<Note>Set targets based on specific categories like days out and gross profit per consultant, allowing you to track and optimise performance effectively.</Note>
			<Table
				resultItems={resultItems}
				tableProperties={TableProperties}
				pageNo={pageNo}
				pageSize={pageSize}
				UpdatePage={updatePage}
				paginationItems={paginationItems}
				totalRecords={totalRecords}
				scaleTable={true}
			/>
			{removeTargetModalIsOpen ? (
				<Modal
					isOpen={removeTargetModalIsOpen}
					onRequestClose={() => {
						setActiveTarget(null);
						setRemoveTargetModalIsOpen(false);
					}}
					style={ModalStyles}
					contentLabel=""
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
				>
					<ConfirmModal
						title="You are about to remove the selected target from this user. This process is irreversible."
						cancelButtonText="Cancel"
						confirmButtonText="Confirm, Remove Target"
						confirmButtonAction={removeTarget}
						cancelButtonAction={() => {
							setActiveTarget(null);
							setRemoveTargetModalIsOpen(false);
						}}
					/>
				</Modal>
			) : null}
			{addTargetModalIsOpen ? (
				<Modal
					isOpen={addTargetModalIsOpen}
					onRequestClose={() => {
						setActiveTarget(null);
						setAddTargetModalIsOpen(false);
					}}
					style={ModalStyles}
					contentLabel="Add User Target"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
				>
					<AddTargetModal
						closeModal={() => {
							setActiveTarget(null);
							setAddTargetModalIsOpen(false);
						}}
						complete={() => {
							updatePage("reset");
							setActiveTarget(null);
							setAddTargetModalIsOpen(false);
						}}
						target={activeTarget}
						userId={user?._id}
					/>
				</Modal>
			) : null}
		</>
	);
};

const mapDispatchToProps = {
	toggleLoading,
	setAlert,
};

export default connect(null, mapDispatchToProps)(UserTargets);
