import { TargetTypes } from "../_enums/targets.enum";

export interface ITarget {
	_id?: string;
	user: string;
	start: Date | null;
	end: Date | null;
	type: TargetTypes;
	value: number;
	reportPeriod?: number;
}

export class TargetModel {
	_id?: string;
	user: string;
	start: Date | null;
	end: Date | null;
	type: TargetTypes;
	value: number;
	reportPeriod?: number;

	constructor(data?: ITarget) {
		if (data?._id) this._id = data?._id;
		this.user = data?.user || "";
		this.start = data?.start || null;
		this.end = data?.end || null;
		this.type = data?.type || null;
		this.value = data?.value || null;
		this.reportPeriod = data?.reportPeriod || 1;
	}
}

export interface ITargetFieldErrors {
	userError?: string;
	startError?: string;
	endError?: string;
	typeError?: string;
	valueError?: string;
}

export class TargetFieldErrorsModel {
	userError?: string;
	startError?: string;
	endError?: string;
	typeError?: string;
	valueError?: string;

	constructor(data?: ITargetFieldErrors) {
		this.userError = data?.userError || "";
		this.startError = data?.startError || "";
		this.endError = data?.endError || "";
		this.typeError = data?.typeError || "";
		this.valueError = data?.valueError || "";
	}
}
