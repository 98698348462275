import styled from 'styled-components'
import { RelSize, GetTableRowHighlightColour } from '../mixins'
import theme from '../theme'
import {
	SearchSelect,
	SelectOptions,
	SelectOption,
	HelpWrapperStyled,
} from "../forms/inputs";
import { StandardButtonAnchor, StandardCancelAnchor } from '../type/buttons';
import { darken } from 'polished';

export const SortWrapper = styled.div`
	background-color: ${theme.green};
	border-radius: 50%;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	display: ${({ active }) => active ? "block" : "none"};
	height: ${RelSize(25, 14)};
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	top: -${RelSize(10, 14)};
	width: ${RelSize(25, 14)};
`;

export const THeadLabel = styled.div`
	align-items: center;
	background-color: ${props => props.external ? theme.formGroupBg : props.sub ? theme.white : theme.quarterinaryGreen};
	display: flex;
	height: ${RelSize(50, 14)};
	margin-bottom: ${props => props.external ? 0 : RelSize(5, 14)};
	min-width: ${RelSize(30, 14)};
	padding: ${RelSize(5, 14)} ${props => props.help && props.filter ? RelSize(50, 14) : RelSize(35, 14)} ${RelSize(5, 14)} ${RelSize(18, 14)};
	position: relative;
	> span {
		font-size: ${RelSize(12, 14)};
	}
	${HelpWrapperStyled} {
		top: 50%;
		transform: translateY(-50%);
		right: 5px;
	}
	${props => props.vertical && `
		align-items: flex-end;
		height: ${RelSize(85, 14)};
		padding: ${RelSize(5, 14)} ${props.help && props.filter ? RelSize(50, 14) : RelSize(10, 14)} ${RelSize(5, 14)} ${RelSize(10, 14)};
		> span {
			transform: translateY(-10px) rotate(300deg);
			width: 30px;
		}
	`}
	${props => props.notVisible && `
		padding: 0;
	`}
	&:hover {
		${SortWrapper} {
			display: block;
		}
	}
`;

export const FilterSearch = styled.div`
	background-color: ${theme.borderGrey};
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy43MDciIGhlaWdodD0iMTcuNzA3IiB2aWV3Qm94PSIwIDAgMTcuNzA3IDE3LjcwNyI+CiAgPGcgaWQ9Ikdyb3VwXzMxIiBkYXRhLW5hbWU9Ikdyb3VwIDMxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzE1OCIgZGF0YS1uYW1lPSJQYXRoIDE1OCIgZD0iTTQ4Ny45NDEsMzA3OS42NjZhNy4xNjYsNy4xNjYsMCwxLDEtNy4xNjYtNy4xNjdBNy4xNjYsNy4xNjYsMCwwLDEsNDg3Ljk0MSwzMDc5LjY2NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NzMuNjA4IC0zMDcyLjUpIiBmaWxsPSJub25lIiBzdHJva2U9IiM3YzdjN2MiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPGxpbmUgaWQ9IkxpbmVfMTIiIGRhdGEtbmFtZT0iTGluZSAxMiIgeDI9IjMuNiIgeTI9IjMuNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIuNCAxMi40KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=');
	background-repeat: no-repeat;
	background-size: 15px 15px;
	background-position: 10px 50%;
	margin-bottom: ${RelSize(5, 14)};
    input {
		background-color: transparent;
        border: 0;
		color: ${theme.secondaryGrey};
        display: block;
        font-size: ${RelSize(12, 14)};
		line-height: 19px;
        padding: ${RelSize(15, 12)} ${RelSize(15, 12)} ${RelSize(15, 12)} ${RelSize(35, 12)};
        width: 100%;
    }
	${SearchSelect} {
		background-color: transparent;
        border: 0;
		color: ${theme.secondaryGrey};
        display: block;
        font-size: ${RelSize(12, 14)};
		font-weight: 300;
		line-height: 19px;
        padding: ${RelSize(15, 12)} ${RelSize(35, 12)} ${RelSize(15, 12)} ${RelSize(35, 12)};
        width: 100%;
		span {
			display: block;
			line-height: 19px;
			max-width: ${RelSize(80, 12)};
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	${SelectOptions} {
		background-color: ${theme.borderGrey};
		${SelectOption} {
			span {
				color: ${theme.secondaryGrey};
				font-weight: 300;
			}
		}
	}
`;

export const TableWrapper = styled.div`
    background:
        linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0)),
        linear-gradient(-90deg, white 0%, rgba(255, 255, 255, 0)) 100% 0,
        linear-gradient(90deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0)),
        linear-gradient(-90deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0)) 100% 0;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 100px 100%, 100px 100%, 30px 100%, 30px 100%;
    background-attachment: local, local, scroll, scroll;
    display: block;
    margin-bottom: ${RelSize(20, 16)};
    max-width: 100%;
	min-height: ${props => props.noMin ? 0 : RelSize(300, 16)};
	padding-top: ${RelSize(10, 16)};
	${props => props.fixHeight && `
		max-height: ${RelSize(400, 16)};
		background:
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.08), rgba(0,0,0,0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.08), rgba(0,0,0,0)) 0 100%;
		background-size: 100% 30px, 100% 30px, 100% 14px, 100% 14px;
		background-attachment: local, local, scroll, scroll;
		background-repeat: no-repeat;
	`}
    overflow-y: auto;
    width: 100%;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
	  width: 5px;
	}
	&::-webkit-scrollbar-track {
	  background: transparent;
	}
	&::-webkit-scrollbar-thumb {
	  background: ${theme.primaryGreen};
	}
    table {
		border: ${props => props.external ? 0 : props.sub ? 0 : "1px solid transparent"};
        border-collapse: collapse;
        font-size: ${RelSize(14, 16)};
        min-width: ${props => props.full ? 0 : props.scaleTable ? RelSize(768, 16) : `calc(${theme.maxWidth} - 100px)`};
        width: 100%;
        th, td {
            min-width: ${props => props.slim ? RelSize(66, 14) : RelSize(80, 14)};
            padding: ${RelSize(15, 14)};
            text-align: left;
            button {
                width: 100%;
            }
            span, label {
                align-items: center;
                display: flex;
				line-height: 1em;
            }
            img {
                max-width: ${RelSize(100, 14)}
            }
        }
        th {
			border-bottom: ${props => props.external ? 0 : `1px solid ${theme.borderGrey}`};
			color: ${props => props.external || props.approval ? theme.primaryGreen : props.sub ? theme.primaryGreen : theme.white};
			padding: ${props => props.approval ? RelSize(15, 14) : 0};
            position: relative;
            a {
                cursor: pointer;
            }
			${props => (!props.external && !props.sub) && `
				&:first-child {
					${THeadLabel}, ${FilterSearch} {
						border-bottom-left-radius: ${RelSize(10, 14)};
						border-top-left-radius: ${RelSize(10, 14)}
					}
				}
				&:last-child {
					${THeadLabel}, ${FilterSearch} {
						border-bottom-right-radius: ${RelSize(10, 14)};
						border-top-right-radius: ${RelSize(10, 14)}
					}
				}
			`}
			&:not(:last-child) {
				border-right: ${props => props.external ? 0 : `1px solid ${theme.white}`};
			}
			&.defaultRequestFilter {
				${FilterSearch} {
					background-color: ${darken(0.1, theme.borderGrey)}
				}
			}
        }
        td {
			border: 1px solid ${theme.borderGrey};
            a {
                cursor: pointer;
                display: block;
				text-align: right;
                width: 100%;
				&.inlineCta {
					color: ${theme.primaryGreen};
					font-weight: normal;
					width: auto;
				}
				&.clipboardCopy {
					background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTkuNSAxNi41TDE5LjUgNC41TDE4Ljc1IDMuNzVIOUw4LjI1IDQuNUw4LjI1IDcuNUw1LjI1IDcuNUw0LjUgOC4yNVYyMC4yNUw1LjI1IDIxSDE1TDE1Ljc1IDIwLjI1VjE3LjI1SDE4Ljc1TDE5LjUgMTYuNVpNMTUuNzUgMTUuNzVMMTUuNzUgOC4yNUwxNSA3LjVMOS43NSA3LjVWNS4yNUwxOCA1LjI1VjE1Ljc1SDE1Ljc1Wk02IDlMMTQuMjUgOUwxNC4yNSAxOS41TDYgMTkuNUw2IDlaIiBmaWxsPSIjMDgwMzQxIi8+DQo8L3N2Zz4=");
					background-size: 15px 15px;
					background-position: right center;
					background-repeat: no-repeat;
					color: ${theme.primaryGreen};
					padding-right: 20px;
					text-align: left;
				}
            }
			span {
				color: ${theme.secondaryGrey};
				> div {
					> span {
						color: ${theme.primaryGreen};
						font-size: ${RelSize(12, 14)};
						white-space: nowrap;
						&:not(:last-child) {
							margin-bottom: ${RelSize(5, 12)};
						}
						a {
							color: ${theme.primaryGreen};
						}
						&.hasTimesheet {
							padding-right: ${RelSize(20, 12)} !important;
							position: relative;
							&::after {
								// background-color: ${props => props.theme.primaryGreen};
								content: "";
								height: ${RelSize(15, 12)};
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								width: ${RelSize(15, 12)};
							}
							&.timesheetApproved {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQyLjk1OCAtNjkyOS41MDIpIj4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTk3IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTk3IiBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0My45NTggNjkzMC41MDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiMxZGFkN2UiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTA2MjgiIGRhdGEtbmFtZT0iUGF0aCAxMDYyOCIgZD0iTTI1Ny45NTgsNjkzOC4wNjZ2My42NzJsMy4yODYsMy42NzIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjI3NiAtMy4zOTEpIiBmaWxsPSJub25lIiBzdHJva2U9IiMxZGFkN2UiIHN0cm9rZS13aWR0aD0iMiIvPgogIDwvZz4KPC9zdmc+Cg==");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.timesheetRejected {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQyLjk1OCAtNjkyOS41MDIpIj4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTk3IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTk3IiBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0My45NTggNjkzMC41MDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiNlMjMxNDEiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTA2MjgiIGRhdGEtbmFtZT0iUGF0aCAxMDYyOCIgZD0iTTI1Ny45NTgsNjkzOC4wNjZ2My42NzJsMy4yODYsMy42NzIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjI3NiAtMy4zOTEpIiBmaWxsPSJub25lIiBzdHJva2U9IiNlMjMxNDEiIHN0cm9rZS13aWR0aD0iMiIvPgogIDwvZz4KPC9zdmc+Cg==");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.timesheetPending {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQyLjk1OCAtNjkyOS41MDIpIj4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTk3IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTk3IiBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0My45NTggNjkzMC41MDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNWJjMDAiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTA2MjgiIGRhdGEtbmFtZT0iUGF0aCAxMDYyOCIgZD0iTTI1Ny45NTgsNjkzOC4wNjZ2My42NzJsMy4yODYsMy42NzIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjI3NiAtMy4zOTEpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNWJjMDAiIHN0cm9rZS13aWR0aD0iMiIvPgogIDwvZz4KPC9zdmc+Cg==");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.pendingLeftApprovedRight {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjAuOTczIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2Y1YmMwMCIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNDg4IiBzdG9wLWNvbG9yPSIjZjViYzAwIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC41MTciIHN0b3AtY29sb3I9IiNjM2I5MWQiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMWRhZDdlIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQxLjk1OCAtNjkwNC41MDIpIj4KICAgIDxwYXRoIGlkPSJTdWJ0cmFjdGlvbl8yIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDIiIGQ9Ik0xMTkuOTU4LDUxOTkuNWE5LDksMCwxLDEsOS05QTkuMDEsOS4wMSwwLDAsMSwxMTkuOTU4LDUxOTkuNVptMC0xNmE3LDcsMCwxLDAsNyw3QTcuMDA4LDcuMDA4LDAsMCwwLDExOS45NTgsNTE4My41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MzkuNTQzIDcwMzMuNDYpIHJvdGF0ZSgtOTApIiBmaWxsPSJ1cmwoI2xpbmVhci1ncmFkaWVudCkiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNzE4IiBkYXRhLW5hbWU9IlBhdGggMTA3MTgiIGQ9Ik0yNTcuOTU4LDY5MzguMDY2djMuNjcybDMuMjg2LDMuNjcyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNyAtMjguMjM2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.approvedLeftPendingRight {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjAuOTczIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzFkYWQ3ZSIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNDg4IiBzdG9wLWNvbG9yPSIjMWRhZDdlIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC41MTciIHN0b3AtY29sb3I9IiNmNWJjMDAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZjViYzAwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQxLjk1OCAtNjkwNC41MDIpIj4KICAgIDxwYXRoIGlkPSJTdWJ0cmFjdGlvbl8yIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDIiIGQ9Ik0xMTkuOTU4LDUxOTkuNWE5LDksMCwxLDEsOS05QTkuMDEsOS4wMSwwLDAsMSwxMTkuOTU4LDUxOTkuNVptMC0xNmE3LDcsMCwxLDAsNyw3QTcuMDA4LDcuMDA4LDAsMCwwLDExOS45NTgsNTE4My41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MzkuNTQzIDcwMzMuNDYpIHJvdGF0ZSgtOTApIiBmaWxsPSJ1cmwoI2xpbmVhci1ncmFkaWVudCkiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNzE4IiBkYXRhLW5hbWU9IlBhdGggMTA3MTgiIGQ9Ik0yNTcuOTU4LDY5MzguMDY2djMuNjcybDMuMjg2LDMuNjcyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNyAtMjguMjM2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.approvedLeftRejectedRight {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjAuOTczIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzFkYWQ3ZSIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNDg4IiBzdG9wLWNvbG9yPSIjMWRhZDdlIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC41MTciIHN0b3AtY29sb3I9IiNlMjMxNDEiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZTIzMTQxIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQxLjk1OCAtNjkwNC41MDIpIj4KICAgIDxwYXRoIGlkPSJTdWJ0cmFjdGlvbl8yIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDIiIGQ9Ik0xMTkuOTU4LDUxOTkuNWE5LDksMCwxLDEsOS05QTkuMDEsOS4wMSwwLDAsMSwxMTkuOTU4LDUxOTkuNVptMC0xNmE3LDcsMCwxLDAsNyw3QTcuMDA4LDcuMDA4LDAsMCwwLDExOS45NTgsNTE4My41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MzkuNTQzIDcwMzMuNDYpIHJvdGF0ZSgtOTApIiBmaWxsPSJ1cmwoI2xpbmVhci1ncmFkaWVudCkiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNzE4IiBkYXRhLW5hbWU9IlBhdGggMTA3MTgiIGQ9Ik0yNTcuOTU4LDY5MzguMDY2djMuNjcybDMuMjg2LDMuNjcyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNyAtMjguMjM2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.rejectedLeftApprovedRight {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjAuOTczIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2UyMzE0MSIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNDg4IiBzdG9wLWNvbG9yPSIjZTIzMTQxIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC41MTciIHN0b3AtY29sb3I9IiMxZGFkN2UiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMWRhZDdlIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQxLjk1OCAtNjkwNC41MDIpIj4KICAgIDxwYXRoIGlkPSJTdWJ0cmFjdGlvbl8yIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDIiIGQ9Ik0xMTkuOTU4LDUxOTkuNWE5LDksMCwxLDEsOS05QTkuMDEsOS4wMSwwLDAsMSwxMTkuOTU4LDUxOTkuNVptMC0xNmE3LDcsMCwxLDAsNyw3QTcuMDA4LDcuMDA4LDAsMCwwLDExOS45NTgsNTE4My41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MzkuNTQzIDcwMzMuNDYpIHJvdGF0ZSgtOTApIiBmaWxsPSJ1cmwoI2xpbmVhci1ncmFkaWVudCkiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNzE4IiBkYXRhLW5hbWU9IlBhdGggMTA3MTgiIGQ9Ik0yNTcuOTU4LDY5MzguMDY2djMuNjcybDMuMjg2LDMuNjcyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNyAtMjguMjM2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.pendingLeftRejectedRight {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjAuOTczIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2Y1YmMwMCIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNDg4IiBzdG9wLWNvbG9yPSIjZjViYzAwIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC41MTciIHN0b3AtY29sb3I9IiNlMjMxNDEiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZTIzMTQxIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQxLjk1OCAtNjkwNC41MDIpIj4KICAgIDxwYXRoIGlkPSJTdWJ0cmFjdGlvbl8yIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDIiIGQ9Ik0xMTkuOTU4LDUxOTkuNWE5LDksMCwxLDEsOS05QTkuMDEsOS4wMSwwLDAsMSwxMTkuOTU4LDUxOTkuNVptMC0xNmE3LDcsMCwxLDAsNyw3QTcuMDA4LDcuMDA4LDAsMCwwLDExOS45NTgsNTE4My41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MzkuNTQzIDcwMzMuNDYpIHJvdGF0ZSgtOTApIiBmaWxsPSJ1cmwoI2xpbmVhci1ncmFkaWVudCkiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNzE4IiBkYXRhLW5hbWU9IlBhdGggMTA3MTgiIGQ9Ik0yNTcuOTU4LDY5MzguMDY2djMuNjcybDMuMjg2LDMuNjcyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNyAtMjguMjM2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
							&.rejectedLeftPendingRight {
								&::after {
									background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjAuOTczIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2UyMzE0MSIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNDg4IiBzdG9wLWNvbG9yPSIjZTIzMTQxIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC41MTciIHN0b3AtY29sb3I9IiNmNWJjMDAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZjViYzAwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfOTUxNiIgZGF0YS1uYW1lPSJHcm91cCA5NTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQxLjk1OCAtNjkwNC41MDIpIj4KICAgIDxwYXRoIGlkPSJTdWJ0cmFjdGlvbl8yIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDIiIGQ9Ik0xMTkuOTU4LDUxOTkuNWE5LDksMCwxLDEsOS05QTkuMDEsOS4wMSwwLDAsMSwxMTkuOTU4LDUxOTkuNVptMC0xNmE3LDcsMCwxLDAsNyw3QTcuMDA4LDcuMDA4LDAsMCwwLDExOS45NTgsNTE4My41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MzkuNTQzIDcwMzMuNDYpIHJvdGF0ZSgtOTApIiBmaWxsPSJ1cmwoI2xpbmVhci1ncmFkaWVudCkiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwNzE4IiBkYXRhLW5hbWU9IlBhdGggMTA3MTgiIGQ9Ik0yNTcuOTU4LDY5MzguMDY2djMuNjcybDMuMjg2LDMuNjcyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNyAtMjguMjM2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN2M3YzdjIiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
									background-repeat: no-repeat;
									background-position: 50% 50%;
									background-size: 15px;
								}
							}
						}
					}
				}
			}
        }
        thead {
            tr {
                border-bottom: ${props => props.external ? 0 : `1px solid ${theme.borderGrey}`};
            }
        }
		${StandardButtonAnchor}, ${StandardCancelAnchor} {
			white-space: nowrap;
		}
    }
	${props => props.stack && `
		@media all and (max-width: 768px) {
			table {
				display: block;
				min-width: 100%;
				width: 100%;
				thead {
					display: none;
				}
				tbody {
					display: block;
					tr {
						display: block;
						margin-bottom: 50px;
						width: 100%;
						td {
							display: block;
							width: 100%;
						}
					}
				}
			}
		}
	`}
`;

export const TableScrollContainer = styled.div`
    max-height: 50vh;
    overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
	  width: 5px;
	}
	&::-webkit-scrollbar-track {
	  background: transparent;
	}
	&::-webkit-scrollbar-thumb {
	  background: ${theme.primaryGreen};
	}
`;


export const TableBody = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

export const Tr = styled.tr`
    cursor: ${props => props.clickable ? "pointer" : "default"};
	transition: background-color ease-in 0.2s;
    ${props => props.clickable && `
        transition: all ease-in 0.2s;
        &:hover {
            background-color: ${theme.borderGrey};
            &:not(:last-child) {
                background-color: ${theme.borderGrey};
            }
        }
    `};
	${props => props.highlightRow && `
		background-color: ${GetTableRowHighlightColour(props.highlightRow)};
	`}
	${props => props.tagHighlight && `
		background-image: linear-gradient(to right, ${props.tagHighlight} 10px, transparent 10px);
	`}
	${props => props.fixedFirstCol && `
		> th:first-child, > td:first-child {
			background-color: ${theme.white};
			left: 0;
			position: sticky;
			transition: background-color ease-in 0.2s;
			z-index: 10;
		}
	`}
	${props => props.activeRow && `
		background-color: ${theme.borderGrey};
		${props.fixedFirstCol && `
			> th:first-child, > td:first-child {
				background-color: ${theme.borderGrey};
			}
		`}
	`}
`;

export const FilterYesNo = styled.div`
    align-items: center;
    display: flex;
    margin-top: ${RelSize(5, 14)};
    > div {
        position: relative;
    }
    input {
        display: none;
        &:checked {
            ~ label {
                &::after {
                    color: ${theme.black};
                    content: "✓";
                    left: ${RelSize(14, 14)};
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    label {
        font-size: ${RelSize(14, 14)};
        font-weight: 400;
        padding: ${RelSize(10, 14)} ${RelSize(10, 14)} ${RelSize(10, 14)} ${RelSize(45, 14)};
        &::before {
            border: 1px solid ${theme.black};
            content: "";
            height: ${RelSize(38, 14)};
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: ${RelSize(38, 14)};
        }
    }
    a {
        font-size: ${RelSize(12, 14)};
    }
`;

export const FilterPlaceholder = styled.div`
	background-color: ${theme.borderGrey};
    height: ${RelSize(49, 14)};
	margin-bottom: ${RelSize(5, 14)};
`;

export const SortButton = styled.a`
	border-radius: 50%;
	display: block;
	height: ${RelSize(25, 14)};
	position: relative;
	width: ${RelSize(25, 14)};
	&::before {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUyMDgzIDUuNzA4MzNMNS40NzkxNyAxLjc1TDkuNDM3NSA1LjcwODMzIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		content: "";
		height: ${RelSize(12, 14)};
		left: 50%;
		left: calc(50% + 2px);
		position: absolute;
		transform: translateX(-50%);
		top: ${RelSize(3, 14)};
		width: ${RelSize(12, 14)};
		${({ asc }) => asc && `
			height: ${RelSize(14, 14)};
			top: ${RelSize(2, 14)};
			width: ${RelSize(14, 14)};
		`}
	}
	&::after {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUyMDgzIDUuNzA4MzNMNS40NzkxNyAxLjc1TDkuNDM3NSA1LjcwODMzIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		bottom: ${RelSize(3, 14)};
		content: "";
		height: ${RelSize(12, 14)};
		left: 50%;
		left: calc(50% - 2px);
		position: absolute;
		transform: translateX(-50%) rotate(180deg);
		width: ${RelSize(12, 14)};
		${({ desc }) => desc && `
			bottom: ${RelSize(2, 14)};
			height: ${RelSize(14, 14)};
			width: ${RelSize(14, 14)};
		`}
	}
`;

export const Asc = styled.a`
	bottom: ${RelSize(20, 14)};
    cursor: pointer;
	height: ${RelSize(10, 14)};
    position: absolute;
    right: ${RelSize(5, 14)};
	width: ${RelSize(20, 14)};
    &::after {
        border-bottom: ${RelSize(10, 14)} solid ${props => props.alt ?
		props.active ?
			props.theme.white :
			props.theme.primaryGreen
		:
		props.active ?
			props.theme.primaryGreen :
			props.theme.white};
        border-left: ${RelSize(10, 14)} solid transparent;
        border-right: ${RelSize(10, 14)} solid transparent;
        content: "";
		left: 0;
		position: absolute;
		top: 0;
    }
`;

export const Desc = styled.a`
    bottom: ${RelSize(5, 14)};
    cursor: pointer;
	height: ${RelSize(10, 14)};
    position: absolute;
    right: ${RelSize(5, 14)};
	width: ${RelSize(20, 14)};
    &::after {
        border-left: ${RelSize(10, 14)} solid transparent;
        border-right: ${RelSize(10, 14)} solid transparent;
        border-top: ${RelSize(10, 14)} solid ${props => props.alt ?
		props.active ?
			props.theme.white :
			props.theme.primaryGreen
		:
		props.active ?
			props.theme.primaryGreen :
			props.theme.white};
        content: "";
		left: 0;
		position: absolute;
		top: 0;
    }
`;

export const TabletTablethItem = styled.div`
   ${props => props.showArrow && `
     &::after {
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${props.theme.black};
        position: absolute;
        right: ${RelSize(5, 14)};
        top: 50%;
        transform: translateY(-50%);
        ${props.rotate && `
            border-bottom: 0;
            border-top: 10px solid ${props.theme.black};
        `}
    `}
`;

export const TJsx = styled.div`
	align-items: center;
	display: flex;
	p {
		margin: 0 ${RelSize(5, 14)} 0 0;
	}
`;

export const VerificationWrapper = styled.div`
	.status {
		align-items: center;
		display: flex;
		margin-bottom: ${RelSize(5, 14)};
		white-space: nowrap;
	}
	.failed {
		color: ${theme.red};
	}
	.success {
		color: ${theme.trafficLightGreen};
	}
	.partial {
		color: ${theme.trafficLightAmber};
	}
`;

export const TableColumnIconStyled = styled.a`
	display: block;
	right: ${RelSize(5, 14)};
	position: absolute;
	top: ${RelSize(2, 14)};
	i {
		display: block;
		height: ${RelSize(16, 14)};
		transform: ${({ rotate }) => rotate ? "rotate(180deg)" : ""};
		width: ${RelSize(20, 14)};
	}
	svg {
		display: block;
		height: 100%;
		width: 100%;
	}
`;

export const ApplicantWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	.vacancyName {
		margin-bottom: ${RelSize(10, 14)};
	}
	a {
		text-align: center !important;
	}
`;

export const TopScrollbarWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 12px;
	margin-bottom: -${RelSize(10, 16)};
	overflow-x: auto;
	overflow-y: hidden;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;

	&::-webkit-scrollbar {
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: ${theme.primaryGreen};
		border-radius: 4px;
	}
`;

export const TopScrollbarContent = styled.div`
	height: 1px;
	visibility: hidden;
`;
