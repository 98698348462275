import React, { useEffect, useRef } from "react";
import { TopScrollbarWrapper, TopScrollbarContent } from "../../styles/tables/table";

interface TopScrollbarProps {
	tableRef: React.RefObject<HTMLDivElement>;
}

const TopScrollbar: React.FC<TopScrollbarProps> = ({ tableRef }) => {
	const scrollbarRef = useRef<HTMLDivElement>(null);
	const isSyncingScrollbar = useRef(false);
	const isSyncingTable = useRef(false);

	useEffect(() => {
		const tableElement = tableRef.current;
		const scrollbarElement = scrollbarRef.current;
		const contentElement = scrollbarElement?.firstElementChild as HTMLElement | null;

		if (!tableElement || !scrollbarElement || !contentElement) return;

		// Set the scrollbar content width to match the table's scrollable width
		const setScrollbarWidth = () => {
			contentElement.style.width = `${tableElement.scrollWidth}px`;
		};

		// Synchronize the scrollbar when the table scrolls
		const onTableScroll = () => {
			try {
				if (isSyncingTable.current) {
					isSyncingTable.current = false;
					return;
				}
				isSyncingScrollbar.current = true;
				scrollbarElement.scrollLeft = tableElement.scrollLeft;
			} catch (error) {
				console.error("Error in onTableScroll:", error);
			}
		};

		// Synchronize the table when the scrollbar scrolls
		const onScrollbarScroll = () => {
			if (isSyncingScrollbar.current) {
				isSyncingScrollbar.current = false;
				return;
			}
			isSyncingTable.current = true;
			tableElement.scrollLeft = scrollbarElement.scrollLeft;
		};

		try {
			// Initial setup
			setScrollbarWidth();
		} catch (error) {
			console.error("Error in useEffect:", error);
		}

		// Only use ResizeObserver if it's supported
		let resizeObserver: ResizeObserver | null = null;
		let resizeHandler: (() => void) | null = null;

		try {
			if (typeof ResizeObserver !== "undefined") {
				resizeObserver = new ResizeObserver(() => {
					setScrollbarWidth();
				});
				resizeObserver.observe(tableElement);
			} else {
				resizeHandler = () => {
					setScrollbarWidth();
				};
				window.addEventListener("resize", resizeHandler);
			}
		} catch (error) {
			resizeHandler = () => {
				setScrollbarWidth();
			};
			window.addEventListener("resize", resizeHandler);
		}

		tableElement.addEventListener("scroll", onTableScroll, { passive: true });
		scrollbarElement.addEventListener("scroll", onScrollbarScroll, { passive: true });

		// Cleanup event listeners and observers on unmount
		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
			if (resizeHandler) {
				window.removeEventListener("resize", resizeHandler);
			}
			tableElement.removeEventListener("scroll", onTableScroll);
			scrollbarElement.removeEventListener("scroll", onScrollbarScroll);
		};
	}, [tableRef]);

	return (
		<TopScrollbarWrapper ref={scrollbarRef}>
			<TopScrollbarContent />
		</TopScrollbarWrapper>
	);
};

export default TopScrollbar;
